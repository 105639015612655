import React from 'react';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Button, Typography, TextField, Stack } from '@mui/material';
import { Formik, Form } from 'formik';
import { object, ref, string } from 'yup';
import { useFirebaseAuth } from 'src/hooks';
import Fade from '@mui/material/Fade';
import Bugsnag from '@bugsnag/js';

const validationSchema = object({
  currentPass: string().required('Senha atual requerida'),
  newPass: string()
    .test('len', 'A senha deve conter no mínimo 8 caracteres.', (val) => val != undefined && val.length > 8)
    .required('Nova senha requerida'),
  confirmPass: string()
    .oneOf([ref('newPass')], 'Nova senha não são identicas')
    .required('Confirmação da nova senha requerida'),
});

export const UpdatePasswordForm = () => {
  const { authUser, updateCurrentUserPassword, unlinkAuthProvider, sendPasswordResetEmail } = useFirebaseAuth();

  const initialValues = {
    currentPass: '',
    newPass: '',
    confirmPass: '',
  };
  const [passChangeSuccess, setPassChangeSuccess] = React.useState(false);
  const [recoverPasswordSent, setRecoverPasswordSent] = React.useState(false);

  const onSubmitHandler = (values, actions) => {
    updateCurrentUserPassword(values.currentPass, values.newPass)
      .then(() => {
        console.log('Password updated!');
        setPassChangeSuccess(true);
        setTimeout(() => {
          setPassChangeSuccess(false);
          window.location.reload(false);
        }, 200);
      })
      .catch((error) => {
        console.log(error);
      });
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const onForgotPasswordHandler = () => {
    sendPasswordResetEmail(authUser.email)
      .then(() => {
        setRecoverPasswordSent(true);
      })
      .catch((error) => {
        Bugsnag.notify(error);
      });
  };

  const onUnlinkEmailHandler = () => {
    unlinkAuthProvider('password')
      .then((user) => {
        console.log('Account Unlinking success', user);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log('Account Unlinking error', error);
      });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitHandler}>
      {({ values, touched, errors, handleChange, isSubmitting }) => (
        <Form noValidate autoComplete='off'>
          <Stack spacing={2}>
            <Typography variant='h5'>Senha Atual</Typography>
            <TextField
              fullWidth
              id='currentPass'
              name='currentPass'
              label='Senha Atual'
              type='password'
              value={values.currentPass}
              onChange={handleChange}
              error={touched.currentPass && Boolean(errors.currentPass)}
              helperText={touched.currentPass && errors.currentPass}
            />
            <Typography>
              Esqueceu sua senha?{' '}
              <Button onClick={onForgotPasswordHandler} variant={'outlined'}>
                Enviar para meu email
              </Button>
            </Typography>
            <Fade in={recoverPasswordSent} timeout={1000}>
              <Typography color={'error'}>Verifique seu email com o codigo e link para trocar sua senha</Typography>
            </Fade>
            <Typography variant='h5'>Criar nova senha</Typography>
            <TextField
              fullWidth
              id='newPass'
              name='newPass'
              label='Nova Senha'
              type='password'
              value={values.newPass}
              onChange={handleChange}
              error={touched.newPass && Boolean(errors.newPass)}
              helperText={touched.newPass && errors.newPass}
            />
            <Typography sx={{ mt: 1 }} variant='h5'>
              Confirmar Senha
            </Typography>
            <TextField
              fullWidth
              id='confirmPass'
              name='confirmPass'
              label='Confirmar Senha'
              type='password'
              value={values.confirmPass}
              onChange={handleChange}
              error={touched.confirmPass && Boolean(errors.confirmPass)}
              helperText={touched.confirmPass && errors.confirmPass}
            />
            <Box>
              <Button type='submit' disabled={isSubmitting} startIcon={<SaveOutlinedIcon />} variant='contained'>
                Salvar nova senha
              </Button>
              <Button variant={'outlined'} color='error' onClick={onUnlinkEmailHandler}>
                Desvincular email da conta
              </Button>
            </Box>
            {passChangeSuccess && (
              <Typography sx={{ mt: 1 }} color={'red'}>
                Senha salva com sucesso
              </Typography>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

UpdatePasswordForm.propTypes = {};
