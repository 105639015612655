import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import LocalBarOutlinedIcon from '@mui/icons-material/LocalBarOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BoxIcon from '@mui/icons-material/Inventory';
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  ListItemButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useAtom } from 'jotai';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { perfilAtom } from 'src/atoms/auth';
import { useFirebaseAuth } from 'src/hooks';
import { Logo } from './logo';
import { NavItem } from './nav-item';
import { RequerPermissao } from './permission';
const getInitials = (str) => {
  if (!str) return '';

  const words = str.replace(/[^a-z0-9 ]/gi, '').split(' ');
  const initials = words.map((word) => word[0]?.toUpperCase()).join('');

  return initials;
};

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    right: '20px',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  const router = useRouter();
  const { authUser } = useFirebaseAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false,
  });

  const [perfil, setPerfil] = useAtom(perfilAtom);
  // localStorage.setItem('perfil', perfil)

  useEffect(
    () => {
      if (!router.isReady) {
        return;
      }

      if (open) {
        onClose?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.asPath],
  );

  useEffect(() => {
    if (perfil?.produtoraSelecionada) {
      const produtoraAtualizada = authUser?.produtoras.find((el) => el.id === perfil.produtoraSelecionada.id);

      if (produtoraAtualizada) {
        setPerfil((p) => ({ ...p, produtoraSelecionada: produtoraAtualizada }));
      }
    }
  }, [authUser?.produtoras, perfil.produtoraSelecionada, setPerfil]);

  console.log('Produtora: ', perfil.produtoraSelecionada);

  const [open1, setOpen1] = React.useState(false);
  const handleClickOpen = () => {
    setOpen1(true);
  };
  const handleClose = () => {
    setOpen1(false);
  };

  // Menu......SuperAdmin
  const [expanded, setExpanded] = React.useState('');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Menu......Produtor
  const [expanded1, setExpanded1] = React.useState('');
  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded1(newExpanded ? panel : false);
  };

  // Menu......Ticketeira
  const [expanded2, setExpanded2] = React.useState('');
  const handleChange2 = (panel) => (event, newExpanded) => {
    setExpanded2(newExpanded ? panel : false);
  };

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          marginTop: '96px',
        }}
      >
        <div>
          <Box sx={{ px: 2 }}>
            <Box sx={{ display: 'flex', mb: 1, mt: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={perfil?.titulo ?? perfil?.tipo} sx={{ position: 'static' }}>
                  {getInitials(perfil?.titulo ?? perfil?.tipo)}
                </Avatar>
              </Box>
              <Box>
                <Box className='displayflexcenter'>
                  <Typography color='neutral.900' variant='p' sx={{ mr: 1, ml: 2 }}>
                    {perfil?.titulo ?? perfil?.tipo ?? 'Não conectado'}
                  </Typography>
                  <IconButton color='primary' onClick={handleClickOpen}>
                    <KeyboardArrowDownOutlinedIcon />
                  </IconButton>
                  <Dialog fullScreen open={open1} onClose={handleClose} TransitionComponent={Transition}>
                    <AppBar sx={{ position: 'relative', background: '#ffffff' }}>
                      <Toolbar>
                        <IconButton color='primary' sx={{ my: 2 }}>
                          <Logo />
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                    <Box className='displayflex'>
                      <Box sx={{ width: '280px', px: 2, py: 3 }}>
                        <Box className='displayflex' sx={{ alignItems: 'center' }}>
                          <Box>
                            <Avatar alt={perfil?.titulo ?? perfil?.tipo} sx={{ position: 'static' }}>
                              {getInitials(perfil?.titulo ?? perfil?.tipo)}
                            </Avatar>
                          </Box>
                          <Box>
                            <Box className='displayflexcenter' sx={{ ml: 2 }}>
                              <Typography color='neutral.900' variant='p' sx={{ mr: 1 }}>
                                {perfil?.titulo ?? perfil?.tipo ?? 'Não conectado'}
                              </Typography>
                              <IconButton color='error'>
                                <LogoutOutlinedIcon />
                              </IconButton>
                            </Box>
                            <Box>
                              {perfil?.cnpj && (
                                <Typography color='neutral.500' variant='p' sx={{ ml: 2 }}>
                                  {perfil?.cnpj}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <Button fullWidth onClick={handleClose} variant='outlined'>
                            Voltar
                          </Button>
                        </Box>
                        <Divider sx={{ borderColor: '#2D3748', my: 3 }} />
                        <Box>
                          <MenuList>
                            <MenuItem>
                              <ListItemIcon>
                                <HomeOutlinedIcon fontSize='small' />
                              </ListItemIcon>
                              <ListItemText>Inicio</ListItemText>
                            </MenuItem>
                            <MenuItem>
                              <ListItemIcon>
                                <SettingsOutlinedIcon fontSize='small' />
                              </ListItemIcon>
                              <ListItemText>Configurações</ListItemText>
                            </MenuItem>
                          </MenuList>
                        </Box>
                      </Box>
                      <Box sx={{ width: 'calc(100% - 280px)', px: 5 }}>
                        {authUser?.perfil === 'SuperAdmin' && (
                          <>
                            <Box sx={{ mt: 3 }}>
                              <Typography color='neutral.900' variant='h5'>
                                Super Admin
                              </Typography>
                            </Box>
                            <Grid
                              item
                              xs={12}
                              sx={{ mt: 1 }}
                              onClick={() => setPerfil({ ...perfil, tipo: 'SuperAdmin', titulo: 'Super Admin' })}
                            >
                              <List className='borda radius'>
                                <NextLink href='/' passHref>
                                  <ListItem
                                    onClick={handleClose}
                                    disablePadding
                                    secondaryAction={
                                      <IconButton edge='end' aria-label='delete'>
                                        <NavigateNextIcon />
                                      </IconButton>
                                    }
                                  >
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <Avatar sx={{ m: 1 }}>
                                          <CloseIcon />
                                        </Avatar>
                                      </ListItemIcon>
                                      <ListItemText primary='Administrativo' secondary='Descrição' />
                                    </ListItemButton>
                                  </ListItem>
                                </NextLink>
                              </List>
                            </Grid>
                          </>
                        )}

                        {authUser?.ticketeira?.admin && (
                          <>
                            <Box sx={{ mt: 3 }}>
                              <Typography color='neutral.900' variant='h5'>
                                Ticketeira
                              </Typography>
                            </Box>

                            <Grid item xs={12} sx={{ mt: 1 }}>
                              <List className='borda radius' onClick={handleClose}>
                                <NextLink href='/' passHref>
                                  <ListItem
                                    onClick={() =>
                                      setPerfil({
                                        tipo: 'Ticketeira',
                                        titulo: authUser?.ticketeira?.nome,
                                      })
                                    }
                                    disablePadding
                                    secondaryAction={
                                      <IconButton edge='end' aria-label='delete'>
                                        <NavigateNextIcon />
                                      </IconButton>
                                    }
                                  >
                                    <ListItemButton>
                                      <ListItemIcon>
                                        <Avatar sx={{ m: 1 }}>
                                          <CloseIcon />
                                        </Avatar>
                                      </ListItemIcon>

                                      <ListItemText primary={authUser?.ticketeira?.nome} secondary='Descrição' />
                                    </ListItemButton>
                                  </ListItem>
                                </NextLink>
                              </List>
                            </Grid>
                          </>
                        )}

                        <Box sx={{ mt: 3 }}>
                          <Typography color='neutral.900' variant='h5'>
                            Produtora
                          </Typography>
                        </Box>
                        <Grid item xs={12} sx={{ mt: 1 }}>
                          <List className='borda radius'>
                            {authUser?.produtoras.map((produtora) => (
                              <NextLink href='/' passHref key={produtora.id}>
                                <ListItem
                                  onClick={() => {
                                    setPerfil({
                                      tipo: 'Produtoras',
                                      produtoraSelecionada: produtora,
                                      titulo: produtora.nome,
                                      cnpj: produtora.cnpj,
                                    });
                                    handleClose();
                                  }}
                                  disablePadding
                                  secondaryAction={
                                    <IconButton edge='end' aria-label='delete'>
                                      <NavigateNextIcon />
                                    </IconButton>
                                  }
                                >
                                  <ListItemButton>
                                    <ListItemIcon>
                                      <Avatar sx={{ m: 1 }}>
                                        <CloseIcon />
                                      </Avatar>
                                    </ListItemIcon>
                                    <ListItemText primary={produtora.nome} secondary='Descrição' />
                                  </ListItemButton>
                                </ListItem>
                              </NextLink>
                            ))}
                          </List>
                        </Grid>
                      </Box>
                    </Box>
                  </Dialog>
                </Box>
                {perfil?.cnpj && (
                  <Typography color='neutral.500' variant='p' sx={{ ml: 2 }}>
                    {perfil?.cnpj}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </div>
        <Divider sx={{ borderColor: '#2D3748', my: 3 }} />
        {perfil.tipo === 'Produtoras' && (
          <div>
            <Box sx={{ flexGrow: 1 }}>
              <RequerPermissao superAdmin produtora={{ Geral: 'Ver' }}>
                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel1'}
                  onChange={handleChange1('panel1')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <HomeOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Inicio</Typography>
                  </AccordionSummary>
                </Accordion>

                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel2'}
                  onChange={handleChange1('panel2')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <CalendarTodayOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Eventos</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem key={'Novo evento'} href={'/eventos/novo'} title={'Novo evento'} />
                    <NavItem key={'Eventos'} href={'/eventos'} title={'Eventos'} />
                    <NavItem key={'Rascunhos'} href={'/eventos/rascunhos'} title={'Rascunhos'} />
                    <NavItem
                      key={'Mapas de assentos'}
                      href={'/eventos/mapas-de-assentos'}
                      title={'Mapas de assentos'}
                    />
                    <NavItem key={'Locais de evento'} href={'/eventos/locais/'} title={'Locais de evento'} />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel3'}
                  onChange={handleChange1('panel3')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <GroupOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Equipes</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem
                      key={'Gerenciar pessoas'}
                      href={'/team/eventsteammanagerpeople'}
                      title={'Gerenciar pessoas'}
                    />
                    <NavItem
                      key={'Equipe administrativa'}
                      href={'/team/eventsteammanageradmin'}
                      title={'Equipe administrativa'}
                    />
                    <NavItem key={'Equipe de evento'} href={'/eventsteamevent'} title={'Equipe de evento'} />
                    {/* <NavItem
                  key={'Afiliados'}
                  href={'/team/eventsteamaffiliates'}
                  title={'Afiliados'} /> */}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel4'}
                  onChange={handleChange1('panel4')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <MenuBookOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Cardápios</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem key={'Gerenciar cardápios'} href={'/cardapios'} title={'Gerenciar cardápios'} />
                    <NavItem key={'Gerenciar produtos'} href={'/cardapios/produtos'} title={'Gerenciar produtos'} />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel5'}
                  onChange={handleChange1('panel5')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <LocalBarOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Bares e cozinhas</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem key={'Gerenciar'} href={'/eventskitchenmanager'} title={'Gerenciar'} />
                    <NavItem key={'Adicionar'} href={'/kitchen/eventskitchenmanagercreate'} title={'Adicionar'} />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel9'}
                  onChange={handleChange1('panel9')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <DashboardOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Artes e layouts</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem
                      key={'Personalizar landing page'}
                      href={'/design/personalizelandingpage'}
                      title={'Personalizar landing page'}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel11'}
                  onChange={handleChange1('panel11')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <MonitorOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Gestão de dispositivos</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem key={'POS'} href={'/device/produtora/pos'} title={'POS'} />
                    <NavItem key={'Impressoras'} href={'/device/produtora/printers'} title={'Impressoras'} />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel12'}
                  onChange={handleChange1('panel12')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <LocalShippingOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Fornecedores</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem key={'Lista de fornecedores'} href={'/caterer/caterer'} title={'Lista de fornecedores'} />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel13'}
                  onChange={handleChange1('panel13')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <SettingsOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Sistema</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem key={'Log de alterações'} href={'/system/systemlog'} title={'Log de alterações'} />
                    <NavItem key={'Notificações automáticas'} href={''} title={'Notificações automáticas'} />
                    <NavItem key={'Conta produtora'} href={'/system/produceraccount'} title={'Conta produtora'} />
                  </AccordionDetails>
                </Accordion>
              </RequerPermissao>

              <RequerPermissao superAdmin produtora={{ Financeiro: 'Ver' }}>
                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel6'}
                  onChange={handleChange1('panel6')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <PaidOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Financeiro</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem
                      key={'Central de estornos'}
                      href={'/financial/financialreversal'}
                      title={'Central de estornos'}
                    />
                    <NavItem key={'Retirar dinheiro'} href={'/financial/withdrawmoney'} title={'Retirar dinheiro'} />
                    <NavItem key={'Extratos'} href={'/financial/extract'} title={'Extratos'} />
                  </AccordionDetails>
                </Accordion>
              </RequerPermissao>

              <RequerPermissao superAdmin produtora={{ Pdv: 'Ver' }}>
                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel15'}
                  onChange={handleChange1('panel15')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <BoxIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>PDVs</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem key={'Adicionar PDV'} href={'/pdv/adicionar'} title={'Adicionar'} />
                    <NavItem key={'Lista de PDVs'} href={'/pdv/listar'} title={'Lista de PDVs'} />
                  </AccordionDetails>
                </Accordion>
              </RequerPermissao>

              <RequerPermissao superAdmin produtora={{ Analises: 'Ver' }}>
                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded1 === 'panel10'}
                  onChange={handleChange1('panel10')}
                >
                  <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <EqualizerOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Análises</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem key={'Vendas'} href={'/analyze/analyzesale'} title={'Vendas'} />
                    <NavItem key={'Tendências'} href={'/analyze/tendencies'} title={'Tendências'} />
                    <NavItem
                      key={'Validação de ingressos'}
                      href={'/analyze/ticketvalidation'}
                      title={'Validação de ingressos'}
                    />
                    <NavItem key={'Trafego'} href={'/analyze/traffic'} title={'Trafego'} />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{ backgroundColor: 'transparent', border: 'none' }}
                  expanded={expanded === 'panel5'}
                  onChange={handleChange('panel5')}
                >
                  <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                    <SavingsOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                    <Typography>Ferramentas</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ borderTop: 'none' }}>
                    <NavItem key={'Simulador de custos'} href={'/tools/costsimulator'} title={'Simulador de custos'} />
                    <NavItem key={'Tarefas'} href={'/tools/tasks'} title={'Tarefas'} />
                  </AccordionDetails>
                </Accordion>
              </RequerPermissao>

              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded1 === 'panel14'}
                onChange={handleChange1('panel14')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <HelpOutlineOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Ajuda</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem key={'Ver faq'} href={'/faq/faq'} title={'Ver faq'} />
                </AccordionDetails>
              </Accordion>
            </Box>
          </div>
        )}

        {perfil.tipo === 'Ticketeira' && (
          <div>
            <Box sx={{ flexGrow: 1 }}>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded2 === 'panel1'}
                onChange={handleChange2('panel1')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <HomeOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Inicio</Typography>
                </AccordionSummary>
                {/* <AccordionDetails
                sx={{ borderTop: 'none' }}>
                <Typography>
                  Lorem ipsum dolor
                </Typography>
              </AccordionDetails> */}
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded2 === 'panel2'}
                onChange={handleChange2('panel2')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <CalendarTodayOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Eventos</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem key={'Eventos'} href={'/events/eventsgeneral'} title={'Lista de eventos'} />
                  <NavItem key={'Locais de evento'} href={'/eventos/locais'} title={'Locais de evento'} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded2 === 'panel3'}
                onChange={handleChange2('panel3')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <TrackChangesOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Marketing</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem key={'Gerenciar cupons'} href={'/marketing/managercupons'} title={'Gerenciar cupons'} />
                  <NavItem key={'Impulsionar evento'} href={'/marketing/boostevent'} title={'Impulsionar evento'} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded2 === 'panel4'}
                onChange={handleChange2('panel4')}
              >
                <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                  <PersonOutlineOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Usuários</Typography>
                </AccordionSummary>
                {/* <AccordionDetails
                sx={{ borderTop: 'none' }}>
                <Typography>
                  Lorem ipsum dolor
                </Typography>
              </AccordionDetails> */}
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded2 === 'panel5'}
                onChange={handleChange2('panel5')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <BusinessCenterOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Produtoras</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem
                    key={'Gestão de produtoras'}
                    href={'/producers/producersmanage'}
                    title={'Gestão de produtoras'}
                  />
                  <NavItem key={'Criar conta'} href={'/producers/producersmanagecreate'} title={'Criar conta'} />
                  <NavItem
                    key={'Central de aprovação'}
                    href={'/producers/approvalcenter'}
                    title={'Central de aprovação'}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded2 === 'panel6'}
                onChange={handleChange2('panel6')}
              >
                <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                  <AccountBalanceWalletOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Gestão de integrações</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem
                    key={'Gateways de pagamento'}
                    href={'/gatewaypay/gestaointegracoes'}
                    title={'Gateways de Pagamento'}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded2 === 'panel13'}
                onChange={handleChange2('panel13')}
              >
                <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                  <PaidOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Financeiro</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem
                    key={'Aprovar transferencias'}
                    href={'/producers/approvetransfers'}
                    title={'Aprovar transferencias'}
                  />
                  <NavItem key={'Valores devidos'} href={'/producers/debt'} title={'Valores devidos'} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded2 === 'panel7'}
                onChange={handleChange2('panel7')}
              >
                <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                  <PaidOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Gestão de taxas</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem key={'Ingressos'} href={'/taxas/ingressos'} title={'Ingressos'} />
                  <NavItem key={'Bares'} href={'/taxas/bares'} title={'Bares'} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded1 === 'panel15'}
                onChange={handleChange1('panel15')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <BoxIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>PDVs</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem key={'Adicionar PDV'} href={'/pdv/adicionar'} title={'Adicionar'} />
                  <NavItem key={'Lista de PDVs'} href={'/pdv/listar'} title={'Lista de PDVs'} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded2 === 'panel8'}
                onChange={handleChange2('panel8')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <MonitorOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Gestão de Dispositivos</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem key={'POS'} href={'/device/ticketeira/pos'} title={'POS'} />
                  <NavItem key={'Impressoras'} href={'/device/ticketeira/printers'} title={'Impressoras'} />
                  <NavItem key={'Outros'} href={'/device/ticketeira/others'} title={'Outros'} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded2 === 'panel9'}
                onChange={handleChange2('panel9')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <EqualizerOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Análises e relatórios</Typography>
                </AccordionSummary>
                {/* <AccordionDetails
                sx={{ borderTop: 'none' }}>
                <NavItem
                  key={'Vendas'}
                  href={'/analyze/analyzesale'}
                  title={'Vendas'} />
              </AccordionDetails> */}
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded2 === 'panel10'}
                onChange={handleChange2('panel10')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <SettingsOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Sistema</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem key={'Log de alterações'} href={'/system/systemlog'} title={'Log de alterações'} />
                  <NavItem key={'Notificações automáticas'} href={''} title={'Notificações automáticas'} />
                  <NavItem key={'Conta produtora'} href={'/system/produceraccount'} title={'Conta produtora'} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded1 === 'panel14'}
                onChange={handleChange1('panel14')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <HelpOutlineOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Ajuda</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem key={'Ver FAQ'} href={'/faq/faq'} title={'Ver faq'} />
                  <NavItem key={'Gerenciar FAQ'} href={'/faq/managefaq'} title={'Gerenciar FAQ'} />
                </AccordionDetails>
              </Accordion>
            </Box>
          </div>
        )}

        {perfil.tipo === 'SuperAdmin' && (
          <div>
            <Box sx={{ flexGrow: 1 }}>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <HomeOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Inicio</Typography>
                </AccordionSummary>
                {/* <AccordionDetails
                sx={{ borderTop: 'none' }}>
                <Typography>
                  Lorem ipsum dolor
                </Typography>
              </AccordionDetails> */}
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                  <CalendarTodayOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Evento</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem key={'Categorias'} href={'/eventos/categorias'} title={'Categorias'} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
                  <LocalBarOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Produtos</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem key={'Gestão de Categorias'} href={'/cardapios-global/categorias'} title={'Categorias'} />
                  <NavItem
                    key={'Gestão de produtos'}
                    href={'/cardapios-global/produtos'}
                    title={'Gestão de produtos'}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                  <PersonOutlineOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Usuários</Typography>
                </AccordionSummary>
                <NavItem key={'Gestão de usuário'} href={'/superadmin/usuarios'} title={'Gestão de usuário'} />
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
              >
                <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                  <SavingsOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Gestão de Taxas</Typography>
                </AccordionSummary>
                <NavItem key={'Gestão de taxas'} href={'/superadmin/taxas'} title={'Gestão de taxas'} />
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded === 'panel6'}
                onChange={handleChange('panel6')}
              >
                <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                  <PaidOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Financeiro</Typography>
                </AccordionSummary>
                <NavItem key={'Relatorios'} href={'/superadmin/relatorios'} title={'Relatórios'} />
                <NavItem key={'Saldo e extrato'} href={'/superadmin/extrato'} title={'Saldo e extrato'} />
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded === 'panel7'}
                onChange={handleChange('panel7')}
              >
                <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                  <MonitorOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Gestão de Dispositivos</Typography>
                </AccordionSummary>
                {/* <AccordionDetails
                sx={{ borderTop: 'none' }}>
                <Typography>
                  Lorem ipsum dolor
                </Typography>
              </AccordionDetails> */}
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded === 'panel8'}
                onChange={handleChange('panel8')}
              >
                <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                  <SettingsOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Sistemas</Typography>
                </AccordionSummary>
                {/* <AccordionDetails
                sx={{ borderTop: 'none' }}>
                <Typography>
                  Lorem ipsum dolor
                </Typography>
              </AccordionDetails> */}
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', border: 'none' }}
                expanded={expanded1 === 'panel14'}
                onChange={handleChange1('panel14')}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <HelpOutlineOutlinedIcon fontSize='small' sx={{ mt: '2px', mr: 1 }} />
                  <Typography>Ajuda</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: 'none' }}>
                  <NavItem key={'Ver faq'} href={'/faq/faq'} title={'Ver faq'} />
                  <NavItem key={'Gerenciar FAQ'} href={'/faq/managefaq'} title={'Gerenciar FAQ'} />
                  <NavItem key={'Categorias'} href={'/faq/categorias'} title={'Categorias'} />
                </AccordionDetails>
              </Accordion>
            </Box>
          </div>
        )}

        <Divider sx={{ borderColor: '#2D3748' }} />
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor='left'
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.100',
            color: '#000000',
            width: 280,
          },
        }}
        variant='permanent'
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor='left'
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.100',
          color: '#000000',
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant='temporary'
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
