import React from 'react';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

export const Loading = (props) => {
  let text = 'Carregando...';
  let loading = false;
  if (props.text && typeof props.text === 'string') {
    text = props.text;
    loading = true;
  }
  if (props.text && typeof props.text === 'boolean') {
    loading = props.text;
  }

  return (
    <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Stack justifyContent='center' alignItems='center' spacing={2} sx={{ width: '100%', height: '90vh' }}>
        <CircularProgress color='inherit' />
        <InputLabel sx={{ color: 'inherit' }}>{text}</InputLabel>
      </Stack>
    </Backdrop>
  );
};

export const GlobalLoading = Loading;
export default Loading;
