import { createSvgIcon } from '@mui/material/utils';

export const Help = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <g>
      <g>
        <path d="M10.4,9.6l-0.2,2.1H9.3L9.1,9.2c0.9-0.2,2.1-0.8,2.1-1.9c0-0.8-0.5-1.5-1.7-1.5c-0.7,0-1.2,0.2-1.5,0.4L7.6,5.5
          C8,5.2,8.8,5,9.6,5c1.7,0,2.8,0.9,2.8,2.3C12.4,8.6,11.2,9.4,10.4,9.6z M9.7,15c-0.4,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8
          c0.5,0,0.8,0.4,0.8,0.8C10.6,14.7,10.2,15,9.7,15z"/>
      </g>
      <path d="M10,1c-5,0-9,4-9,9c0,5,4,9,9,9c5,0,9-4,9-9C19,5,15,1,10,1z M10,17.8c-4.3,0-7.8-3.5-7.8-7.8S5.7,2.2,10,2.2
        c4.3,0,7.8,3.5,7.8,7.8S14.3,17.8,10,17.8z"/>
    </g>
  </svg>,
  'Help'
);
