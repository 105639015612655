import React from 'react';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Button, Typography, TextField, Stack } from '@mui/material';
import { Formik, Form } from 'formik';
import { object, ref, string } from 'yup';
import { useFirebaseAuth } from 'src/hooks';

const validationSchema = object({
  newPass: string()
    .test('len', 'A senha deve conter no mínimo 8 caracteres.', (val) => val != undefined && val.length > 8)
    .required('Nova senha requerida'),
  confirmPass: string()
    .oneOf([ref('newPass')], 'Nova senha não são identicas')
    .required('Confirmação da nova senha requerida'),
});

export const LinkEmailForm = () => {
  const { authUser, linkEmailProvider } = useFirebaseAuth();

  const initialValues = {
    email: authUser.email,
    newPass: '',
    confirmPass: '',
  };
  const [passChangeSuccess, setPassChangeSuccess] = React.useState(false);

  const onSubmitHandler = (values, actions) => {
    linkEmailProvider(values.email, values.newPass)
      .then((usercred) => {
        var user = usercred.user;
        console.log('Account linking success', user);
        setPassChangeSuccess(true);
        setTimeout(() => {
          setPassChangeSuccess(false);
          window.location.reload(false);
        }, 2000);
      })
      .catch((error) => {
        console.log('Account linking error', error);
      });
    actions.setSubmitting(false);
    actions.resetForm();
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitHandler}>
      {({ values, touched, errors, handleChange, isSubmitting }) => (
        <Form noValidate autoComplete='off'>
          <Stack spacing={2}>
            {!authUser.email && (
              <>
                <Typography variant='h5'>Email Atual</Typography>
                <TextField
                  fullWidth
                  id='email'
                  name='email'
                  label='Email Atual'
                  type='password'
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </>
            )}
            <Typography variant='h5'>Criar nova senha</Typography>
            <TextField
              fullWidth
              id='newPass'
              name='newPass'
              label='Nova Senha'
              type='password'
              value={values.newPass}
              onChange={handleChange}
              error={touched.newPass && Boolean(errors.newPass)}
              helperText={touched.newPass && errors.newPass}
            />
            <Typography sx={{ mt: 1 }} variant='h5'>
              Confirmar Senha
            </Typography>
            <TextField
              fullWidth
              id='confirmPass'
              name='confirmPass'
              label='Confirmar Senha'
              type='password'
              value={values.confirmPass}
              onChange={handleChange}
              error={touched.confirmPass && Boolean(errors.confirmPass)}
              helperText={touched.confirmPass && errors.confirmPass}
            />
            <Box>
              <Button type='submit' disabled={isSubmitting} startIcon={<SaveOutlinedIcon />} variant='contained'>
                Salvar nova senha
              </Button>
            </Box>
            {passChangeSuccess && (
              <Typography sx={{ mt: 1 }} color={'red'}>
                Senha salva com sucesso
              </Typography>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

LinkEmailForm.propTypes = {};
