import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import useFirebaseAuth from 'src/hooks/useFirebaseAuth';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { Loading } from './parts/loading';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280,
  },
}));

export const DashboardLayout = React.forwardRef((props, ref) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const { authLoading } = useFirebaseAuth();

  if (authLoading) return <Loading />;
  return (
    <>
      <DashboardLayoutRoot>
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {props.children}
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />
    </>
  );
});
