import { useAtomValue } from 'jotai';
import Router from 'next/router';
import { useMemo } from 'react';
import { perfilAtom } from 'src/atoms/auth';
import { useFirebaseAuth } from 'src/hooks';

export const RequerPermissao = ({
  superAdmin = false,
  produtora = {},
  children,
  fallback = null,
  redirect = false,
}) => {
  const perfil = useAtomValue(perfilAtom);
  const { authUser } = useFirebaseAuth();

  const isAllowed = useMemo(() => {
    if (superAdmin && authUser?.perfil === 'SuperAdmin') return true;

    if (!perfil?.produtoraSelecionada?.permissoesAdministrativas) return false;

    const { permissoesAdministrativas } = perfil.produtoraSelecionada;

    if (!permissoesAdministrativas) return false;

    const allow = Object.entries(produtora)
      .map(([permissaoBusca, nivelBusca]) =>
        permissoesAdministrativas.find((permissaoUsuario) => {
          if (permissaoUsuario.permissao !== permissaoBusca) return false;

          if (permissaoUsuario.nivel === 'Editar') return true;

          return nivelBusca === permissaoUsuario.nivel;
        }),
      )
      .some((el) => el);

    return !!allow;
  }, [authUser?.perfil, perfil.produtoraSelecionada, produtora, superAdmin]);

  if (isAllowed) {
    return children;
  } else {
    if (redirect) {
      const redirectPath = typeof redirect === 'boolean' ? '/' : redirect;

      Router.push(redirectPath);
    }

    return fallback;
  }
};
