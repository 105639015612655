import styled from '@emotion/styled';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import useFirebaseAuth from 'src/hooks/useFirebaseAuth';
import { PerfilCadastro } from 'src/pages/perfil/cadastro';
import { PerfilSeguranca } from 'src/pages/perfil/seguranca';
import { Bell as BellIcon } from '../icons/bell';
import { Help } from '../icons/help';
import { Logo } from './logo';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, ...other } = props;

  const { signOut, authUser } = useFirebaseAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openUserMenu = Boolean(anchorEl);
  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const [profileComponent, setProfileComponent] = React.useState(null);
  const openProfileMenu = Boolean(profileComponent);
  const handleOpenProfileComponent = (component) => {
    setProfileComponent(component);
  };
  const handleCloseProfileComponent = () => {
    setProfileComponent(false);
  };

  return (
    <DashboardNavbarRoot
      sx={{
        left: {
          lg: 0,
        },
        width: {
          lg: 'calc(100% - 0px)',
        },
        zIndex: '1300',
      }}
      {...other}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
        }}
      >
        <IconButton
          onClick={onSidebarOpen}
          sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none',
            },
          }}
        >
          <MenuIcon fontSize='small' />
        </IconButton>
        <Box sx={{ p: 3, display: 'flex', alignItems: 'center' }}>
          <NextLink href='/'>
            <Logo />
          </NextLink>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Tooltip title='Help'>
          <IconButton sx={{ m: 1 }}>
            <Help fontSize='small' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Notifications'>
          <IconButton sx={{ m: 1 }}>
            <Badge badgeContent={4} color='primary' variant='dot'>
              <BellIcon fontSize='small' />
            </Badge>
          </IconButton>
        </Tooltip>
        {authUser && (
          <>
            <Typography color='neutral.900' variant='p' sx={{ m: 1 }}>
              {authUser.nome ?? 'Anonímo'}
            </Typography>

            <Button
              id='demo-positioned-button'
              aria-controls={openUserMenu ? 'demo-positioned-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openUserMenu ? 'true' : undefined}
              onClick={handleOpenUserMenu}
              variant='contained'
              sx={{ m: 1 }}
            >
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={openUserMenu}
              onClose={handleCloseUserMenu}
              onClick={handleCloseUserMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <Avatar sx={{ mt: 1 }} alt={authUser.nome} src={authUser.foto} key={authUser.foto} />
                <Box className='displaygrid'>
                  <Typography variant='p' sx={{ ml: 5 }}>
                    {authUser.nome}
                  </Typography>
                  <Typography variant='p' sx={{ ml: 5 }}>
                    {authUser.email}
                  </Typography>
                </Box>
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleOpenProfileComponent(<PerfilCadastro />)}>
                <ListItemIcon>
                  <AccountCircleOutlinedIcon fontSize='small' />
                </ListItemIcon>
                Cadastro
              </MenuItem>
              <MenuItem onClick={() => handleOpenProfileComponent(<PerfilSeguranca />)}>
                <ListItemIcon>
                  <ShieldOutlinedIcon fontSize='small' />
                </ListItemIcon>
                Segurança
              </MenuItem>
              <NextLink href='/login' passHref>
                <MenuItem onClick={signOut}>
                  <ListItemIcon>
                    <Logout fontSize='small' />
                  </ListItemIcon>
                  Sair
                </MenuItem>
              </NextLink>
            </Menu>

            <Dialog
              fullScreen
              open={openProfileMenu}
              onClose={handleCloseProfileComponent}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: 'relative', background: '#ffffff', height: '96px' }}>
                <Toolbar className='displayflexcenterwrap'>
                  <IconButton color='primary' sx={{ mt: 3 }}>
                    <Logo sx={{ height: 42, width: 42 }} />
                  </IconButton>
                  <Button onClick={handleCloseProfileComponent} variant='outlined'>
                    Voltar para portal Plin
                  </Button>
                </Toolbar>
              </AppBar>
              <Box sx={{ px: 5 }}>{profileComponent}</Box>
            </Dialog>
          </>
        )}
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};
