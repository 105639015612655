import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Typography, Tab } from '@mui/material';
import React from 'react';
import { LinkEmailForm } from 'src/components/account/linkEmailForm';
import { UpdatePasswordForm } from 'src/components/account/updatePasswordForm';
import { useFirebaseAuth } from 'src/hooks';

export const PerfilSeguranca = () => {
  const { authUser } = useFirebaseAuth();
  const emailLinked = authUser.providers.includes('password');
  const [currentTab, setCurrentTab] = React.useState('1');
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };
  console.log(authUser.providers);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList variant='fullWidth' onChange={handleChangeTab} aria-label='Trocar Senha'>
            <Tab icon={<AccountCircleOutlinedIcon />} iconPosition='start' label='Senha de acesso' value='1' />
            <Tab icon={<LocationOnOutlinedIcon />} iconPosition='start' label='Senha de 4 digitos' value='2' />
          </TabList>
        </Box>
        <TabPanel value='1'>{emailLinked ? <UpdatePasswordForm /> : <LinkEmailForm />}</TabPanel>
        <TabPanel value='2'>
          <Box className='displayendflex'>
            <Button startIcon={<SaveOutlinedIcon />} variant='contained' sx={{ mt: 1 }}>
              Salvar alterações
            </Button>
          </Box>
          <Box>
            <Typography variant='h5'>Senha 4 digitos atual</Typography>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant='p'>0000</Typography>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

PerfilSeguranca.propTypes = {};

export default PerfilSeguranca;
